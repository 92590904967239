import React from "react";
import { Link } from "react-router-dom";

export enum BtnOption {
  whiteDestructive = "btn-white-destructive",
  white = "btn-white",
  orange = "btn-orange",
  success = "btn-success",
}
export enum IconOption {
  delayed = "delayed.svg",
  done = "done.svg",
  send = "send.svg",
  camera = "camera.svg",
}
interface CustomButtonProps {
  url?: string;
  btnOption: BtnOption;
  icon?: IconOption;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}
const CustomButton = ({
  url,
  btnOption,
  icon,
  text,
  onClick,
}: CustomButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) onClick(e);
  };

  return url ? (
    <Link
      to={url}
      className={`btn ${btnOption}`}
      onClick={(e) => handleClick(e)}
    >
      {icon && <img src={`/assets/svg/${icon}`} alt={icon} />}
      <span>{text}</span>
    </Link>
  ) : (
    <a href="#" className={`btn ${btnOption}`} onClick={(e) => handleClick(e)}>
      {text}
    </a>
  );
};

export default CustomButton;
