import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import IndexPage from '../pages';
import DelayedPage from '../pages/change-delivery';
import DeliveredPage from '../pages/delivered-delivery';
import SuccessDeliveryPage from '../pages/success-delivery';
import TransportPage from './transport';

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route index element={<IndexPage />} /> */}
        <Route path="transport/:code">
          <Route index element={<TransportPage />} />
        </Route>
        <Route path="delivered/:code" element={<DeliveredPage />} />
        <Route path="delayed/:code" element={<DelayedPage />} />
        <Route path="success/:code" element={<SuccessDeliveryPage />} />
        {/*     <Route path="*" element={<Navigate replace to="/404" />} />
            <Route path="404" element={<ErrorPage />} /> */}
      </Route>
    </Routes>
  );
};

export default Navigation;
