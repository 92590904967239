import { useTranslation } from 'react-i18next';

interface AlbaranProps {
  id?: string;
}
const Albaran = ({ id }: AlbaranProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="albaran-container">
      <p className="albaran-container-text">
        {t('Albaran')}
        {id}
      </p>
    </div>
  );
};

export default Albaran;
