import { createContext, useState } from 'react';
import { ShowError } from '../components/Alert/Alert';
import useSessionStorage from '../hooks/useSessionStorage';

import { ITracking } from '../models';
import { apiService } from '../services/api';

// const defaultState: ITracking | null = null;
export const AlbaranContext = createContext<any>(null);

const AlbaranProvider = ({ children }: any) => {
  const [albaran, setAlbaran] = useState<ITracking | null>(null);
  const [sessionObj, setSessionObj] = useSessionStorage('albaran', '');
  const [albaranFrontCode, setAlbaranFrontCode] = useState<string | null>(null);

  const getParamsToState = (params: string) => {
    getItem(params);
  };
  const getItem = async (albaranCode: string) => {
    try {
      setAlbaranFrontCode(albaranCode);
      setSessionObj({ code: albaranCode });
      return true;
    } catch (error: any) {
      ShowError('Error', error.message);
    }
  };

  const updateAlbaran = async (albaranCode: string, option: any) => {
    try {
      const tracking = await apiService.updateTracking(albaranCode, option);
      setAlbaran(tracking);
      setSessionObj(tracking);

      return tracking;
    } catch (error: any) {
      ShowError('Error', error.message);
    }
  };

  const uploadImage = async (image: any) => {
    const urlS3 = await apiService.uploadImage(image);
    return urlS3;
  };

  return (
    <AlbaranContext.Provider
      value={{
        albaran,
        albaranFrontCode,
        setAlbaran,
        setAlbaranFrontCode,
        getItem,
        getParamsToState,
        updateAlbaran,
        uploadImage,
      }}
    >
      {children}
    </AlbaranContext.Provider>
  );
};

export default AlbaranProvider;
