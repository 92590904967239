import config from '../config';

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export class HttpService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async request<T>(
    method: RequestMethod,
    url: string,
    params?: any,
    headers: any = {},
    errHandler?: (err: any) => void
  ) {
    let theUrl = this.baseUrl + url;
    const hasQuery = method === RequestMethod.GET || method === RequestMethod.DELETE;
    const isMultipart = params instanceof FormData;
    if (hasQuery && params) {
      const newUrl = new URL(theUrl);
      Object.keys(params).forEach(key => newUrl.searchParams.append(key, params[key]));
      theUrl = newUrl.toString();
    }
    const data = {
      method,
      headers: {
        ...headers,
        ...(isMultipart
          ? {}
          : {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }),

        // const token = document.head.querySelector('meta[name="csrf-token"]').content;
        // 'X-CSRF-TOKEN': 'OnLxJjLTrgFPwpxWsFct36dhXcvUrnJhAx7aCIC3',
      },
      body: isMultipart ? params : !hasQuery ? JSON.stringify(params ?? {}) : undefined,
    };

    return fetch(theUrl, data)
      .then(async response => {
        if (!response.ok) {
          const err = await response.json();

          throw Error(JSON.stringify({ code: response.status, message: err }));
        }
        return response;
      })
      .then(response => response.json())
      .then(json => json as Promise<T>);
  }

  async get<T>(
    url: string,
    params?: any,
    headers: any = {},
    errHandler?: (err: any) => void
  ) {
    return this.request<T>(RequestMethod.GET, url, params, headers, errHandler);
  }

  async post<T>(
    url: string,
    params?: any,
    headers: any = {},
    errHandler?: (err: any) => void
  ) {
    return this.request<T>(RequestMethod.POST, url, params, headers, errHandler);
  }

  // Not working on Laravel API
  async put<T>(
    url: string,
    params?: any,
    headers: any = {},
    errHandler?: (err: any) => void
  ) {
    return this.request<T>(RequestMethod.PUT, url, params, headers, errHandler);
  }

  async delete<T>(
    url: string,
    params?: any,
    headers: any = {},
    errHandler?: (err: any) => void
  ) {
    return this.request<T>(RequestMethod.DELETE, url, params, headers, errHandler);
  }
}
export const httpService = new HttpService(config.endpoints.api);
