import React, { useContext, useEffect, useRef, useState } from 'react';
import CustomButton, { BtnOption, IconOption } from '../components/Common/CustomButton';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AlbaranContext } from '../context/Albaran.context';
import { HideLoading, ShowError, ShowLoading } from '../components/Alert/Alert';

const DeliveredPage = () => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState<string>();
  const [imgFile, setImgFile] = useState<any>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const searchparams = useParams();
  let navigate = useNavigate();
  const albaranParam = searchparams.code;

  const { albaran, setAlbaran, uploadImage, updateAlbaran, getParamsToState } =
    useContext(AlbaranContext);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (albaran && albaran.delivery_note) setAlbaran({ ...albaran, delivery_note: null });

    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      const url = URL.createObjectURL(file);
      setImageSrc(url);
      setImgFile(file);
    }
  };

  const sendImage = async (isPhoto: boolean) => {
    try {
      ShowLoading();
      const objToSend = {
        ...albaran,
        delayed: false,
        in_time: true,
        date_time: new Date(),
      };
      if (isPhoto) {
        const urlImageS3 = await uploadImage(imgFile);
        if (urlImageS3) {
          await updateAlbaran(albaranParam, {
            ...objToSend,
            delivery_note: urlImageS3.url,
          });
        }
      } else {
        await updateAlbaran(albaranParam, objToSend);
      }
      HideLoading();
      navigate(`/success/${albaranParam}`);
    } catch (error: any) {
      HideLoading();
      ShowError('Error', error.message);
    }
  };

  useEffect(() => {
    if (albaran === null && albaranParam) {
      getParamsToState(albaranParam);
      return;
    }
  }, [albaranParam, albaran]);

  return (
    <div className="page transport-page">
      <div className="albaran-state">
        <p className="">{t('Por favor, fotografía el albarán:')}</p>
        <div className="btns-container">
          <CustomButton
            url="#"
            onClick={() => inputFileRef.current?.click()}
            btnOption={BtnOption.orange}
            icon={IconOption.camera}
            text={t('Activar cámara')}
          />
          {imageSrc ? (
            <img alt="capture" className="img-placeholder" src={imageSrc} />
          ) : null}
          {albaran?.delivery_note && (
            <img src={albaran.delivery_note} alt="albaran" className="img-placeholder" />
          )}
          <input
            ref={inputFileRef}
            style={{ display: 'none' }}
            accept="image/*"
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={e => handleChangeFile(e)}
          />
        </div>

        {imgFile ? (
          <CustomButton
            btnOption={BtnOption.orange}
            text={t('Enviar')}
            onClick={() => sendImage(true)}
          />
        ) : (
          <CustomButton
            btnOption={BtnOption.whiteDestructive}
            text={t('Enviar sin foto')}
            onClick={() => sendImage(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DeliveredPage;
