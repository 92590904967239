import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Albaran from '../components/Albaran/Albaran';
import { useTranslation } from 'react-i18next';
import { AlbaranContext } from '../context/Albaran.context';

const SuccessDeliveryPage = () => {
  const searchparams = useParams();
  const albaranParam = searchparams.code;

  const { t } = useTranslation();
  const { albaran, getParamsToState } = useContext(AlbaranContext);

  useEffect(() => {
    if (albaran === null && albaranParam) {
      getParamsToState(albaranParam);
      return;
    }
  }, [albaranParam, albaran]);

  return (
    <div className="page success-page">
      <Albaran id={albaran?.code} />
      <div className="success-container">
        <img src="/assets/svg/thumb-up.svg" alt="thumb-up" width={43} />
        <p className="success-message">
          {t('¡Gracias por actualizar los datos de entrega!')}
        </p>
      </div>
    </div>
  );
};

export default SuccessDeliveryPage;
