import { HttpService } from './http';
import { ITracking, TrackenurApiInterface } from '../models';
import config from '../config';

export default class ApiService extends HttpService implements TrackenurApiInterface {
  async getTracking(code: string): Promise<ITracking> {
    return await this.get<ITracking>(`/tracking/${code}`);
  }

  async createTracking(code: string): Promise<ITracking> {
    return await this.post<ITracking>(`/tracking/${code}`);
  }

  async updateTracking(code: string, obj: ITracking): Promise<ITracking> {
    return await this.post<ITracking>(`/tracking/${code}`, {
      ...obj,
      _method: 'put',
    });
  }

  async uploadImage(body: any): Promise<string> {
    const data = new FormData();
    data.append('file', body);
    return await this.post<string>(`/upload`, data);
  }
}

export const apiService = new ApiService(`${config.endpoints.api}/api`);
