import React, { forwardRef, useContext, useEffect, useState } from 'react';
import Albaran from '../components/Albaran/Albaran';
import CustomButton, { BtnOption, IconOption } from '../components/Common/CustomButton';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlbaranContext } from '../context/Albaran.context';
import { HideLoading, ShowError, ShowLoading } from '../components/Alert/Alert';

type ButtonProps = React.HTMLProps<HTMLButtonElement>;
const ExampleCustomInput = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ value, onClick, placeholder }, ref) => (
    <button className="btn-ref-picker" onClick={onClick} ref={ref}>
      {value ? value : placeholder}
    </button>
  )
);

const DelayedPage = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [fullDate, setFullDate] = useState<Date | null>(null);

  const [dateError, setDateError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);

  const searchparams = useParams();
  const albaranParam = searchparams;

  const { albaran, updateAlbaran, getParamsToState, albaranFrontCode } =
    useContext(AlbaranContext);

  useEffect(() => {
    if (albaran === null && albaranParam) {
      getParamsToState(albaranParam);
      return;
    }

    setStartDate(new Date(albaran.date_time || new Date()));
    setStartTime(new Date(albaran.date_time || new Date()));
  }, [albaranParam, albaran]);

  const selectDate = (date: Date) => {
    setStartDate(date);
  };
  const selectTime = (date: Date) => {
    setStartTime(date);
  };

  const transformDate = (date: Date, hour: Date) => {
    if (!hour) return;
    const newDate = new Date(startDate || new Date());
    newDate.setHours(hour.getHours(), hour.getMinutes(), hour.getSeconds());

    setFullDate(newDate);
  };
  const sendData = async () => {
    ShowLoading();
    if (!startDate) {
      setDateError(true);
    }
    if (!startTime) {
      setTimeError(true);
    }
    if (!startDate || !startTime) return;

    try {
      const res = await updateAlbaran(albaranFrontCode, {
        ...albaran,
        code: albaranFrontCode,
        date_time: fullDate?.toJSON(),
        delayed: true,
        in_time: false,
      });
      HideLoading();
      if (res) navigate(`/success/${albaranFrontCode}`);
    } catch (error: any) {
      HideLoading();
      ShowError('Error', error.message);
    }
  };

  useEffect(() => {
    transformDate(startDate as Date, startTime as Date);
  }, [startDate, startTime]);

  return (
    <div className="page delayed-page">
      {albaran && (
        <>
          <Albaran id={albaranFrontCode} />
          <div className="delayed-container">
            <div className="picker-container">
              <p className="">{t('Seleccionar estado del albarán:')}</p>

              <div className="picker">
                <img
                  className="picker-icon"
                  src={`/assets/svg/${'calendar.svg'}`}
                  alt={'calendar.svg'}
                />
                <DatePicker
                  selected={startDate}
                  onChange={date => selectDate(date as Date)}
                  dateFormat="dd/MM/yy"
                  className="picker-input"
                  customInput={<ExampleCustomInput />}
                  placeholderText={t('Fecha prevista de entrega')}
                  // onKeyDown={e => e.preventDefault()}
                  // onFocus={e => e.target.blur()}
                  // onFocus={e => avoidKeyboard(e)}
                />
              </div>
              {dateError && (
                <span className="picker-error">Selecciona el día de entrega</span>
              )}
            </div>
            <div className="picker-container">
              <div className="picker">
                <img
                  className="picker-icon"
                  src={`/assets/svg/${'time.svg'}`}
                  alt={'time.svg'}
                />

                <DatePicker
                  selected={startTime}
                  onChange={date => selectTime(date as Date)}
                  customInput={<ExampleCustomInput />}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  className="picker-input"
                  placeholderText={t('Hora prevista de entrega')}
                />
              </div>
              {timeError && (
                <span className="picker-error">Selecciona la fecha de entrega</span>
              )}
            </div>
            <CustomButton
              text={t('Enviar')}
              btnOption={BtnOption.orange}
              icon={IconOption.send}
              onClick={() => {
                sendData();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DelayedPage;
