import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Albaran from '../../components/Albaran/Albaran';
import CustomButton, {
  BtnOption,
  IconOption,
} from '../../components/Common/CustomButton';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { AlbaranContext } from '../../context/Albaran.context';
import { ITracking } from '../../models';

const TransportPage = () => {
  const { t, i18n } = useTranslation();

  const searchparams = useParams();
  const albaranParam = searchparams.code;

  const { albaran, setAlbaran, getParamsToState, albaranFrontCode } =
    useContext(AlbaranContext);

  useEffect(() => {
    const res = getParamsToState(albaranParam);
    return res;
  }, [albaranParam]);

  const settingAlbaranDelayed = () => {
    setAlbaran((state: ITracking) => {
      return { ...state, delayed: true };
    });
  };
  const settingAlbaranIntime = () => {
    setAlbaran((state: ITracking) => {
      return { ...state, in_time: true };
    });
  };

  return (
    <div className="page transport-page">
      {albaranParam && (
        <>
          <LanguageSelector />
          <Albaran id={albaranFrontCode as string} />
          <div className="albaran-state">
            <p className="">{t('Seleccionar estado del albarán:')}</p>
            <div className="btns-container">
              <CustomButton
                url={`/delayed/${albaranFrontCode}`}
                btnOption={BtnOption.whiteDestructive}
                icon={IconOption.delayed}
                text={t('Retrasado')}
                onClick={settingAlbaranDelayed}
              />
              <CustomButton
                url={`/delivered/${albaranFrontCode}`}
                btnOption={BtnOption.success}
                icon={IconOption.done}
                text={t('Entregado')}
                onClick={settingAlbaranIntime}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TransportPage;
