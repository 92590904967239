import React, { useEffect, useState } from 'react';
import i18n from 'i18next';

interface LiOption {
  icon: string;
  text: string;
  i18value: string;
}
const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<LiOption | undefined>(undefined);
  const OPTIONS = [
    { icon: '/assets/svg/uk.svg', text: 'English', i18value: 'en' },
    { icon: '/assets/svg/es.svg', text: 'Español', i18value: 'es-ES' },
    { icon: '/assets/svg/fr.svg', text: 'Français', i18value: 'fr' },
    { icon: '/assets/svg/pl.svg', text: 'Polski', i18value: 'pl' },
  ];

  const toggling = () => setIsOpen(!isOpen);

  const setLanguage = (i18value: string) => {
    return i18n.changeLanguage(i18value);
  };

  const onOptionClicked = (option: LiOption) => () => {
    setSelectedOption(option);
    setIsOpen(false);
    setLanguage(option.i18value);
  };

  useEffect(() => {
    const res = localStorage.getItem('i18nextLng');
    const valueFiltered = OPTIONS.filter((elm: LiOption) => elm.i18value === res);
    const [value] = valueFiltered;

    setSelectedOption(value);
  }, []);

  return (
    <div className="DropDownContainer">
      <div className="DropDownHeader" onClick={toggling}>
        <div className="DropDownHeaderText">
          <img src={selectedOption?.icon} alt={selectedOption?.text} />
          <span>{selectedOption?.text}</span>
        </div>
        <img src="/assets/svg/cheveron-down.svg" alt="cheveron-down" />
      </div>
      {isOpen && (
        <div className="DropDownListContainer">
          <ul className="DropDownList">
            {OPTIONS.map(option => (
              <li
                className="ListItem"
                onClick={onOptionClicked(option)}
                key={Math.random()}
              >
                <img src={option.icon} alt={option.text} />
                <span>{option.text}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="DropDownContainer"></div>
    </div>
  );
};

export default LanguageSelector;
