import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const Alert = withReactContent(Swal);

const buttonColor = '#ff6917';

export const ShowError = (title: string, text: string, didClose?: () => void) => {
  let txt: any = text.indexOf('message') > -1 ? JSON.parse(text) : text;

  if (txt.message) {
    if (txt.message.messages) {
      txt = txt.message.messages.join('\n');
    } else {
      txt = txt.message;
    }
  }
  return Alert.fire({
    icon: 'error',
    title,
    text: txt,
    didClose,
    confirmButtonText: 'Aceptar',
    confirmButtonColor: buttonColor,
  });
};

export const ShowLoading = () => {
  return Alert.fire({
    didOpen: () => {
      Swal.showLoading();
    },
    confirmButtonText: 'Aceptar',
    confirmButtonColor: buttonColor,
  });
};

export const HideLoading = () => {
  Swal.close();
};
