import React from "react";
import { Link, useParams } from "react-router-dom";

const Header = () => {
  const searchparams = useParams();
  const albaranParam = searchparams.code || "";

  return (
    <nav className="navbar">
      <Link to={`/transport/${albaranParam}`}>
        <img src="/assets/img/logo.png" alt="Logo Contenur" className="logo" />
      </Link>
    </nav>
  );
};

export default Header;
