import { useState } from 'react';
import './App.css';
import AlbaranProvider from './context/Albaran.context';
import Navigation from './navigation';

function App(props: any) {
  const [showCamera, setShowCamera] = useState(false);

  return (
    <div className="App">
      <AlbaranProvider>
        <Navigation />
      </AlbaranProvider>
    </div>
  );
}

export default App;
